<template>
  <div class="home">
    <div class="banner pc-banner">
      <img
        class="appimg"
        src="https://bcmarket.obs.cn-south-1.myhuaweicloud.com/files/181144141b144abc81574f8b72bd9895.png"
        alt=""
      />
      <img class="img2" src="../assets/banner.png" alt="" />
      <!-- <div class="">
        <div class="bottom">
            <ul>
              <li v-for="item in productList" :key="item">{{item}}</li>
            </ul>
          </div>
      </div> -->
    </div>
    <div class="test">
      <div class="test_box">
        <div class="title">
          <!-- <span class="titile_text">宝乡通®</span> -->
          <span class="titile_text">宝乡通
            <span class="titile_icon">®</span>
          </span>
          <span class="titile_text1">农资行业垂直供应链数字化公共服务平台</span>
        </div>
        <div class="smallTitle pcdisp">
          不懂数字化运营，我们带你玩，专人指导培训，14个月，客户和销量，增长
          <span>25%</span>
          以上
        </div>
        <div class="smallTitle appdisp">
          <div class="smallTitle_app">
            不懂数字化运营，我们带你玩，
            <div>
              专人指导培训，14个月，客户和销量，增长
              <span>25%</span>
              以上
            </div>
          </div>
        </div>
        <div class="item1">
          <!-- 农资企业信息化底层系统 -->
          <div class="card img1">
            <div class="card_text1">
              农资企业信息化底层系统
            </div>
            <div class="card-line">
              <div class="card_line1"></div>
              <div class="card_line2"></div>
            </div>
            <div class="card_item">
              <div class="card_itemText">
                <div class="carder_itenMain">
                  <span>企业信息:名称、地址、资质证照</span><br>
                  <span>产品信息:通用名、三证、针对作物等</span>
                </div>
                <div class="card_itemTitle">
                  营销体系:
                </div>
                <ul class="card_ul carder_itenMain">
                  <li>私域商城：客一价、一域一价</li>
                  <li>公域商城：宣传推广、直达终端</li>
                  <li>小程序网店：S2B2C，厂(品牌)、<br class="appdisp"> <span class="appdisp1">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>店(服务)、用户连动</li>
                </ul>
              </div>
              <div class="itemIocn">
                <img :src="ImageArr[0]" alt="">
              </div>
            </div>
          </div>
          <!-- 门店进销存收银自动记帐采集工具 -->
          <div class="card img2">
            <div class="card_text1">
              门店进销存收银自动记帐采集工具
            </div>
            <div class="card-line">
              <div class="card_line1"></div>
              <div class="card_line2"></div>
            </div>
            <div class="card_item">
              <div class="card_itemText">
                <div class="carder_itenMain">
                  <span>本身不是目的，方便梳理账目，做经营判断</span><br>
                  <span>实体门店、线上店一体化管理后台</span>
                </div>
                <div class="card_itemTitle carder_itenMain pcdisp">
                  <div class="card_noweight">收货录入、收银记录、赊销记账、物流对账，</div>
                  <div class="card_noweight">一次完成。</div>
                </div>
                <div class="card_itemTitle carder_itenMain card_noMar pcdisp">
                  <div class="card_noweight">财务管理、存货补货、盈利分析、后台监管</div>
                  <div class="card_noweight">一目了然。</div>
                </div>
                <div class="appdisp appCard_itemTitle">
                  <div class="left">
                    <div class="card_itemTitle carder_itenMain">
                      <div class="card_noweight" style="font-weight: 400;">收货录入、收银记录、</div>
                      <div class="card_noweight" style="font-weight: 400;">赊销记账、物流对账，</div>
                      <div class="card_noweight app_card_weight">一次完成</div>
                    </div>
                    <div class="card_itemTitle carder_itenMain card_noMar">
                      <div class="card_noweight" style="font-weight: 400;">财务管理、存货补货、</div>
                      <div class="card_noweight" style="font-weight: 400;">盈利分析、后台监管</div>
                      <div class="card_noweight app_card_weight">一目了然</div>
                    </div>
                  </div>
                  <div class="itemIocn app_itemIocn2">
                    <img :src="ImageArr[1]" alt="">
                  </div>
                </div>
              </div>
              <div class="itemIocn pcdisp">
                <img :src="ImageArr[1]" alt="">
              </div>
            </div>
          </div>
        </div>
        <div class="item1">
          <!-- 小程序网店，本地营销配送软件 -->
          <div class="card card_3 img3">
            <div class="card_text1">
              小程序网店，本地营销配送软件
            </div>
            <div class="card-line">
              <div class="card_line1"></div>
              <div class="card_line2"></div>
            </div>
            <div class="card_item">
              <div class="card_itemText card_itemFlex appCard_itemFlex">
                <div class="carder_itenMain">
                  <span>从坐商零售变为行商批发预售，</span><br>
                  <span>从你找客户到客户找你<span class="pcdisp1">。</span></span>
                </div>
                <div class="card_itemTitle carder_itenMain card_noMar">
                  <div class="card_noweight" style="font-weight: 400;">仓管员、送货员、业务员、收银员<span class="pcdisp1">。</span></div>
                    四人工作，一人搞掂<span class="pcdisp1">。</span>
                </div>
              </div>
              <div class="itemIocn app_itemIocn3">
                <img :src="ImageArr[2]" alt="">
              </div>
            </div>
          </div>
          <!-- 台帐数据自动采集 -->
          <div class="card card_3 img4">
            <div class="card_text1">
              台帐数据自动采集
            </div>
            <div class="card-line">
              <div class="card_line1"></div>
              <div class="card_line2"></div>
            </div>
            <div class="card_item">
              <div class="card_itemText card_itemFlex card_itemWid appCard_itemFlex">
                <div>
                  <span class="app_text">自主上报、自动上报双通道</span>
                </div>
                <div class="card_itemTitle carder_itenMain card_noMar">
                  <div class="card_noweight" style="font-weight: 400;">从单纯财务进销存管理，</div>
                  <span class="card_noweight" style="font-weight: 400;">升级到</span>数字化系统运营。
                </div>
              </div>
              <div class="itemIocn app_itemIocn4">
                <img :src="ImageArr[3]" alt="">
              </div>
            </div>
          </div>
        </div>
        <!-- <div class="item2">
          <div class="item2_title1">系统特点</div>
          <div class="item2_title2">借互联网的势，避互联网的坑</div>
          <div class="item2_title3">
            <div class="card_text card_texts">
              <div>不做</div>
              <div>全国市场买和卖</div>
            </div>
            <div class="card_text">
              <div>专注</div>
              <div>区城市场本地服务</div>
            </div>
          </div>
          <div class="item2_title4">服务价格一对一，信息安全不泄露</div>
        </div> -->
      </div>
    </div>
    <!-- 关系图 -->
    <div class="relation">
      <div class="title">
        <div class="line"></div>
        <div class="text">从工厂到用户全链通</div>
        <div class="line"></div>
      </div>
      <div class="img">
        <img :src="relationImg" alt="" class="pcdisp"/>
        <img :src="relationImg2" alt="" class="appdisp"/>
      </div>
    </div>

    <!-- 系统概述 -->
    <div class="overview">
      <!-- 核心功能 -->
      <div class="coreFunction" ref="platformOverview">
        <div class="coreFunction_header">
          <div class="content">
            <div class="top">
              <div class="icon"></div>
              <div class="text">系统概述</div>
              <div class="icon"></div>
            </div>
          </div>
          <div class="title">云、链、圈、端、数字物连</div>
        </div>
        <div class="bottom">
          <div class="content">
            <div class="item">
              <div class="icon">运营</div>
              <div class="titles">
                <div>省云</div>
                <div>仓</div>
              </div>
              <div class="text">
                每一个省一个虚拟云仓，数据区块链云存储，分省运营，前置仓配送。
              </div>
            </div>
            <div class="item app_item2">
              <div class="icon">技术</div>
              <div class="titles">
                <div>全链</div>
                <div>通</div>
              </div>
              <div class="text">
                垂直供应链：厂家从物料采购到终端营销；横向行业链：从同行厂商到各省市植保、政府监管和售后服务。
              </div>
            </div>
            <div class="item">
              <div class="icon">生态</div>
              <div class="titles">
                <div>行业</div>
                <div>圈</div>
              </div>
              <div class="text">
                物以类聚，人以群分，本地社群行业圈，各细分品类圈。
              </div>
            </div>
            <div class="item">
              <div class="icon">工具</div>
              <div class="titles">
                <div>终端</div>
                <div>控</div>
              </div>
              <div class="text">
                电子智能助手，一店一网，本地化小程序网店，农资AI植保服务。
              </div>
            </div>
            <div class="item">
              <div class="icon">区块链</div>
              <div class="titles">
                <div>数字</div>
                <div>智能</div>
                <div>物连</div>
              </div>
              <div class="text">
                ERP溯源，全链一键通，用银行卡密码技术防控用户信息泄露。
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- 宝秤农资®宝乡通®是什么？ -->
    <!-- <div class="introduce">
      <div class="card" ref="manufacturer">
        <span>宝秤农资®宝乡通®是什么？</span>
      </div>
      <div class="producer">
        <img :src="producerImg" alt="" />
      </div>
    </div> -->

    <!-- 公司动态 (手机端适配样式 均在 home-media.scss 中) -->
    <!-- <div class="projectImg content-box"> -->
      <!-- <span class="title">公司动态</span>
      <div class="news_box">
        <div class="text">
          <div class="new_title">
            <span>公司理念</span>
          </div>
          <div class="text_item">
            <div
              class="item"
              v-for="item in companyPhilosophy"
              :key="item.code"
            >
              <div><img :src="item.img" alt="" /></div>
              <div>{{ item.name }}</div>
              <div>{{ item.introduce }}</div>
            </div>
          </div>
        </div>
        <div class="video">
          <div class="new_title">
            <span>市场巡展</span>
            <span @click="lookMoreNews('2')">查看更多></span>
          </div>
          <div class="video_item">
            <div
              class="item"
              style="cursor: pointer"
              v-for="(item, index) in videoList"
              :key="index"
              @click="goNewsDetail(item, '3')"
            > -->
              <!-- <div class="img gn-rela pc-item">
                <img :src="item.title_image" />
                <img
                  src="@/assets/images/play-btn.png"
                  class="gn-center"
                  style="width: 44px; height: 44px"
                />
              </div>
              <div class="info pc-item">
                <div class="name">
                  {{ item.title }}
                </div>
                <div class="time">
                  {{ item.create_time }}
                </div>
              </div> -->

              <!-- <div class="info mobile-item">
                <div class="name">
                  {{ item.title }}
                </div>
                <div class="time">
                  {{ item.create_time }}
                </div>
              </div>
              <div class="img gn-rela mobile-item">
                <img :src="item.title_image" />
                <img
                  src="@/assets/images/play-btn.png"
                  class="gn-center"
                  style="width: 44px; height: 44px"
                />
              </div> -->
            <!-- </div>
          </div>
        </div>
      </div> -->
    <!-- </div> -->
    <!-- 关于我们 pc端 -->
    <!-- <pc-about /> -->
    <!-- 关于我们 手机端 （由于 关于我们 页面的 pc端和手机端 样式大不一样，所以手机端另做一套代码，便于后期维护） -->
    <!-- <mobile-about /> -->

    <!-- 企业文化、获得荣誉、发展历程 -->
    <!-- <culture :publishData='publishData' /> -->

    <!-- 合作企业 pc端 -->
    <!-- <pc-partner :publishData='publishData' :partners='partners' /> -->
    <!-- 合作企业 手机端 （与 pc端 样式展示不一样，所以手机端另做一套代码） -->
    <!-- <mobile-partner /> -->
    <!-- 通工厂，通农户，农资买卖路路通 -->
      <div class="videoView">
        <div class="title">
          <div class="line"></div>
          <div class="text">通工厂，通农户，农资买卖路路通</div>
          <div class="line"></div>
        </div>
        <div class="big_video" ref="distributor">
          <!-- poster:设置视频封面 -->
          <video
            ref="myVideo"
            controls
            autoplay
            poster="https://bcmarket.obs.cn-south-1.myhuaweicloud.com/20242018/17134032024607313650"
            src="https://bcmarket.obs.cn-south-1.myhuaweicloud.com/20240417/1713346349034376678"
          ></video>
        </div>
      </div>
    </div>
</template>
<script>
// import '@/assets/style/style.css'
import { getPublishListByType } from '@/api/officialWebsite'
import { getArticleList } from '@/api/admin'
import { mapGetters } from 'vuex'
import { menuList, getMenuPath } from '@/utils/website'
import { conversionArr } from '@/utils'
import pcAbout from './official/components/home/pcAbout'
import mobileAbout from './official/components/home/mobileAbout'
import culture from './official/components/home/culture'
import pcPartner from './official/components/home/pcPartner'
import mobilePartner from './official/components/home/mobilePartner'

export default {
  name: 'Home',
  components: {
    pcAbout,
    mobileAbout,
    culture,
    pcPartner,
    mobilePartner
  },
  computed: {
    ...mapGetters(['WEBSITE_MENU', 'IS_LARGE_SCREEN', 'WEBSITE_BANNER'])
  },
  data () {
    const vm = this

    return {
      header: '',
      bannerImgList: [], // 轮播图
      ImageArr: [
        'https://bcmarket.obs.cn-south-1.myhuaweicloud.com/20244915/17131745853128517039',
        'https://bcmarket.obs.cn-south-1.myhuaweicloud.com/20244716/17132320424377481338',
        'https://bcmarket.obs.cn-south-1.myhuaweicloud.com/20245415/17131748902042884998',
        'https://bcmarket.obs.cn-south-1.myhuaweicloud.com/20245515/17131749034059312238'
      ],
      // 轮播图
      swiperOption: {
        slidesPerView: 1,
        centeredSlides: true,
        // 设置分页器
        pagination: {
          el: '.swiper-pagination',
          // 设置点击可切换
          clickable: true
          // 自定义分页器
          // bulletClass: "my-bullet",
          // bulletActiveClass: "my-bullet-active",
          // renderBullet: function (index, className) {
          //   return (
          //       '<span class="' + className + '" >' + (index + 1) + "</span>"
          //   );
          // }
        },
        // 设置前进后退按钮
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev'
        },
        // 设置自动轮播
        autoplay: {
          delay: 8000,
          // 用户操作swiper以后，是否禁止autoplay.默认为false中止
          disableOnInteraction: false
        },
        // 设置轮播可循环
        loop: true,
        on: {
          click: function (e) {
            // 这里有坑，需要注意的是：this 指向的是 swpier 实例，而不是当前的 vue
            // 判断只有点击 了解更多 按钮 才执行此操作
            if (vm.$isMobile) {
              const index =
                this.clickedIndex - this.activeIndex + this.realIndex
              vm.goBannerHref(index)
            } else {
              if (e.target.id === 'learn-about') {
                // loop: true的时候，会多复制2个index,需要手动变下，才可以实现和list的index完全对应
                // 在swiper里注册点击事件，loop true复制的2个假的才可以被点击上，所以点击事件不写在vuetemplate的div上面
                // 关键词： swiper loop true点击失效
                const index =
                  this.clickedIndex - this.activeIndex + this.realIndex
                vm.goBannerHref(index)
              }
            }
          }
        }
      },
      vedioHeight: 160,
      companyNewList: [], // 公司新闻
      industryTrendsList: [], // 行业动态
      videoList: [], // 视频展示
      publishData: {},
      partners: [],
      producerImg: '',
      relationImg: '',
      relationImg2: '',
      companyPhilosophy: []
    }
  },
  created () {
    this.initData()
    this.setDefault()
  },
  mounted () {
    // 1-首页轮播图 5-手机版轮播图
    this.getDetail(this.IS_LARGE_SCREEN ? '1' : '5')
    window.addEventListener('resize', this.resizePage)
  },
  beforeDestroy () {
    window.removeEventListener('resize', this.resizePage)
  },
  methods: {
    async initData () {
      const [res1, res2] = await Promise.all([
        getArticleList({
          page: 1,
          per_page: 6,
          search_data: '',
          advisory_type: '3',
          status: '0',
          start_time: '',
          end_time: '',
          is_platform: '1'
        }),
        getPublishListByType({
          publish_type: ['11', '12'] // 12:企业文化  13:发展历程  14:获得荣誉  18:合作企业
        })
      ])
      this.videoList = res1.list || []
      const arr1 = res2['11']
      const arr2 = res2['12']
      this.companyPhilosophy = arr2
      arr1.forEach((item) => {
        if (item.name === '从工厂到用户全链通') {
          this.relationImg = item.img
        }
        if (item.name === '从工厂到用户全链通(手机版)') {
          this.relationImg2 = item.img
        }
        if (item.name === '宝秤农资®宝乡通®是什么？') {
          this.producerImg = item.img
        }
      })
    },
    // 页面大小改变
    resizePage () {
      this.getDetail(this.IS_LARGE_SCREEN ? '1' : '5')
    },
    // 1 首页轮播图 2  公司新闻 3 行业动态 4 logo 5 手机版轮播图
    async getDetail (type) {
      if (!this.WEBSITE_BANNER.length) {
        await this.$store.dispatch('getWebsiteBannerList')
      }
      const pageData = this.WEBSITE_BANNER
      this.bannerImgList = pageData[type - 1].list // "ba_type": 广告类型 1:链接 2:视频
      console.log(this.bannerImgList, 123213)
      this.$nextTick(() => {
        const dom = this.$refs.banner
        if (dom && dom[0]) {
          this.vedioHeight = dom[0].height
          if (!this.vedioHeight) {
            this.$nextTick(() => {
              this.vedioHeight = this.$refs.banner[0].height
            })
          }
        }
      })
    },
    goBannerHref (index) {
      const bannerItem = this.bannerImgList[index]
      if (bannerItem && bannerItem.href) {
        bannerItem.href.startsWith('http')
          ? window.open(bannerItem.href)
          : this.$router.push({ name: bannerItem.href })
      }
    },
    async setDefault () {
      if (!this.$store.state.WEBSITE_MENU.length) {
        await menuList()
      }

      const item = this.WEBSITE_MENU.find(
        (el) => el.href === getMenuPath(this.$route.path) || el.href === '/home'
      )
      // 设置头图
      this.header = item ? item.top_pic.img : ''
    },
    lookMoreNews (type) {
      if (this.$isMobile) {
        this.$router.push({
          name: 'mobileNews',
          query: {
            type
          }
        })
      } else {
        this.$router.push({
          name: 'news',
          query: {
            type
          }
        })
      }
    },
    handleGo (type) {
      this.$router.push({
        name: 'about',
        query: {
          type
        }
      })
    },
    goNewsDetail (item, type) {
      if (this.$isMobile) {
        if (type === '3') {
          this.$router.push({
            name: 'mobileVideo',
            query: {
              type,
              videoUrl: item.video,
              name: item.title,
              cover: item.title_image
            }
          })
        } else {
          this.$router.push({
            name: 'mobileNewsDetail',
            query: {
              type, // 1:公司新闻  2:行业动态  3:视频展示
              title: item.title
            }
          })
        }
      } else {
        if (type === '3') {
          this.$router.push({
            name: 'video',
            query: {
              type, // 1:公司新闻  2:行业动态  3：视频展示
              name: item.title || '',
              videoUrl: item.video || ''
            }
          })
        } else {
          this.$router.push({
            name: 'newsDetail',
            query: {
              type, // 1:公司新闻  2:行业动态  3：视频展示
              title: item.title
            }
          })
        }
      }
    }
  }
}
</script>

<style lang='scss' scoped>
::v-deep .swiper-button-prev,
.swiper-button-next {
  &:focus {
    outline: none;
  }
}
::v-deep .swiper-button-prev {
  left: 38px;
}
::v-deep .swiper-button-next {
  right: 38px;
}
// 轮播图指示器
::v-deep .rotation .swiper-pagination {
  .swiper-pagination-bullet {
    width: 104px;
    height: 4px;
    border-radius: 0;
    background-color: rgba(255, 255, 255, 0.5);
  }
  .swiper-pagination-bullet-active {
    background-color: #ffffff;
  }
}
</style>

<style lang="scss" scoped>
.banner {
  margin-top: 68px;
  height: 453px;
  width: 100%;
  background-size: cover;
  img {
    width: 100%;
    height: 100%;
  }
  .img1,.appimg {
    display: none;
  }
  .img2 {
    display: block;
  }
}
.swiper-container {
  // height: 350px;
  // background-color: #505355;
  img,
  video {
    width: 100%;
    height: 100%;
  }
  .swiper-button-prev,
  .swiper-button-next {
    color: #00a0ac;
    width: 40px;
    height: 75px;
    border-radius: 4px;
  }
}

.rotation {
  .learn-more {
    width: 174px;
    height: 43px;
    border: 1px solid #ffffff;
    background: rgba(0, 0, 0, 0.2);
    font-size: 18px;
    color: #ffffff;
    left: 435px;
    top: 234px;
    text-decoration: none;
  }
}
.test {
  padding: 65px 0px 75px;
  width: 100%;
  background-color: #f6f6f6;
  .test_box {
    margin: 0 auto;
    width: 1440px;
    color: #333333;
    .title {
      margin-bottom: 34px;
      text-align: center;
      font-size: 34px;
      font-weight: 700;
      .titile_text{
        font-size: 42px;
        position: relative;
        padding: 0 10px;
        font-weight: 700;
        text-align: center;
      }
      .titile_icon {
        font-size: 20px;
        position: absolute;
        top: 0px;
        right: 2px;
      }
    }
    .smallTitle{
        width: 1184px;
        height: 58px;
        background-color: #00a0ac;
        color: #ffffff;
        text-align: center;
        line-height: 58px;
        margin: 0 auto;
        font-size: 28px;
        margin-bottom: 52px;
        font-weight: 400;
        span{
          color: #fdcf57;
          font-size: 30px;
          font-weight: 700;
        }
      }
    .item1 {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;

      .card {
        padding: 60px 50px 33px 46px;
        width: 710px;
        height: 427px;
        // background: #ffffff;
        .card_title {
          display: flex;
          align-items: center;
          justify-content: center;
          .line {
            flex: 1;
            height: 1px;
            background-color: #cccccc;
          }
          .text {
            margin: 0px 20px;
            width: 77px;
            height: 29px;
            background: #2d76d1;
            font-size: 22px;
            font-weight: 700;
            color: #ffffff;
            text-align: center;
            line-height: 29px;
          }
        }
        .card-line{
          display: flex;
          margin: 10px 0 30px;
        }
        .card_text1 {
          font-size: 32px;
          font-weight: 700;
          text-align: left;
          color: #333333;
        }
        .card_line1{
          width: 78px;
          height: 3px;
          background: #00a0ac;
        }
        .card_line2{
          width: 28px;
          height: 3px;
          background: #fdcf57;
        }
        .card_itemFlex{
            height: 157px;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
          }
          .card_itemWid{
            height: 123px;
          }
        .card_itemText{
          font-size: 20px;
          color: #333333;
          flex: 1;
          width:479px;

          .carder_itenMain{
            line-height: 30px;
            // font-weight: 520;
          }
          .card_noMar{
            margin: 0;
          }
        }
        .card_item{
          display: flex;
        }
        .card_itemTitle{
          font-size: 20px;
          font-weight: 700;
          color: #333333;
          margin: 25px 0 4px 0;
          .card_noweight{
            font-weight: 520;
          }
          .card_weight{
            margin: 0;
          }
        }
        .card_ul li{
          list-style: square inside; /* 设置项目符号为实心方块，放置在项目内容之内 */
          list-style-type: disc;
          white-space: nowrap;
        }
        .card_text2 {
          font-size: 26px;
          font-weight: 700;
          text-align: center;
          color: #2d76d1;
        }
        .card_text3 {
          margin-bottom: 21px;
          display: flex;
          justify-content: space-between;
          align-items: center;
          div:nth-child(1) {
            width: 108px;
            height: 29px;
            background: #2d76d1;
            font-size: 20px;
            font-weight: 700;
            color: #ffffff;
            text-align: center;
            line-height: 29px;
          }
          div:nth-child(2) {
            margin-left: 10px;
            flex: 1;
            font-size: 20px;
            font-weight: 700;
            color: #333333;
          }
        }
        .card_text5 {
          font-size: 26px;
          font-weight: 700;
          text-align: center;
          color: #2d76d1;
        }
      }
      .card_3{
        padding-top: 80px;
      }
      .img1{
        background-image: url('https://bcmarket.obs.cn-south-1.myhuaweicloud.com/20240915/17131721494752234451');
        background-repeat: no-repeat; /* 确保图像不会重复 */
        background-position: center; /* 图像在元素中心位置 */
      }
      .img2{
        background-image: url('https://bcmarket.obs.cn-south-1.myhuaweicloud.com/20241815/17131727283289781264');
        background-repeat: no-repeat; /* 确保图像不会重复 */
        background-position: center; /* 图像在元素中心位置 */
      }
      .img3{
        background-image: url('https://bcmarket.obs.cn-south-1.myhuaweicloud.com/20245216/17132323430001133580');
        background-repeat: no-repeat; /* 确保图像不会重复 */
        background-position: center; /* 图像在元素中心位置 */
      }
      .img4{
        background-image: url('https://bcmarket.obs.cn-south-1.myhuaweicloud.com/20245116/17132322838129060967');
        background-repeat: no-repeat; /* 确保图像不会重复 */
        background-position: center; /* 图像在元素中心位置 */
      }
    }
    .item2 {
      padding: 36px 50px 54px;
      margin-top: 37px;
      width: 100%;
      height: 315px;
      background: #ffffff;
      .item2_title1 {
        text-align: center;
        font-size: 36px;
        font-weight: 700;
        color: #333333;
      }
      .item2_title2 {
        margin: 10px 0px 30px;
        font-size: 24px;
        font-weight: normal;
        text-align: center;
      }
      .item2_title3 {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        .card_texts {
          margin-right: 102px;
        }
        .card_text {
          display: flex;
          align-items: center;
          justify-content: center;
          div:nth-child(1) {
            width: 89px;
            height: 45px;
            background: #2d76d1;
            font-size: 36px;
            font-weight: 700;
            text-align: center;
            line-height: 45px;
            color: #ffffff;
          }
          div:nth-child(2) {
            margin-left: 10px;
            flex: 1;
            font-size: 42px;
            font-weight: 700;
            text-align: justifyLeft;
            color: #2d76d1;
          }
        }
      }
      .item2_title4 {
        margin-top: 10px;
        font-size: 42px;
        font-weight: 700;
        text-align: center;
        color: #333333;
      }
    }
  }
}

// 关系图
.relation {
  margin: 0 auto;
  width: 1440px;
  .title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 51px 0px 57px;
    box-sizing: border-box;
    .line {
      height: 1px;
      background-color: #dbdada;
      flex: 1;
    }
    .text {
      margin: 0px 12px;
      font-size: 34px;
      font-weight: 700;
      color: #333333;
      line-height: 24px;
    }
  }
  .img {
    width: 100%;
    display: flex;
    justify-content: center;
    img {
      width: 1325px;
      height: 296px;
    }
  }
}
.overview {
  .coreFunction {
    margin-top: 90px;
    width: 100%;
    .content {
      display: flex;
      justify-content: center;
      width: 100%;
      .top {
        margin: 0 auto;
        display: flex;
        align-items: center;
        .text {
          margin: 0px 20px;
          font-size: 34px;
          font-family: Source Han Sans CN, Source Han Sans CN-Bold;
          font-weight: 700;
          text-align: justifyLeft;
          color: #333333;
          line-height: 51px;
        }
        .icon {
          width: 14px;
          height: 14px;
          background: #e5e5e5;
          transform: rotate(45deg);
        }
      }
    }
    .title {
      margin-top: 30px;
      text-align: center;
      font-size: 30px;
      font-family: Source Han Sans CN, Source Han Sans CN-Regular;
      font-weight: 400;
      text-align: justifyLeft;
      color: #333333;
    }
    .bottom {
      position: relative;
      margin-top: 169px;
      width: 100%;
      height: 358px;
      background-size: cover;
      background-image: url("https://bcmarket.obs.cn-south-1.myhuaweicloud.com/20232122/16953708938442412136");
      .content {
        display: flex;
        justify-content: space-between;
        position: absolute;
        left: 50%;
        bottom: 62px;
        transform: translate(-50%, 0);
        width: 1440px;
        height: 382px;
        .item {
          position: relative;
          padding: 40px 45px 20px 40px;
          width: 258px;
          height: 382px;
          background: #ffffff;
          border: 1px solid #c9c9c9;
          box-sizing: border-box;
          .icon {
            position: absolute;
            top: -9px;
            right: 9px;
            width: 73px;
            height: 29px;
            text-align: center;
            line-height: 29px;
            background-image: url("https://bcmarket.obs.cn-south-1.myhuaweicloud.com/20232122/16953708965833933631");
            background-size: 100% 100%;
            font-size: 16px;
            font-family: Source Han Sans CN, Source Han Sans CN-Regular;
            font-weight: 400;
            color: #ffffff;
          }
          .titles {
            display: flex;
            font-size: 28px;
            font-family: Source Han Sans CN, Source Han Sans CN-Bold;
            font-weight: 700;
            text-align: justifyLeft;
            color: #333333;
            line-height: 30px;
            div:nth-child(1) {
              padding-bottom: 10px;
              border-bottom: 2px solid #00a0ac;
            }
          }
          .text {
            margin-top: 45px;
            font-size: 20px;
            font-family: Source Han Sans CN, Source Han Sans CN-Regular;
            font-weight: 400;
            text-align: justifyLeft;
            color: #333333;
            line-height: 34px;
          }
        }
      }
    }
  }
}
.introduce {
  background-color: #d0ebf5;
  .card {
    display: flex;
    justify-content: center;
    margin-bottom: 60px;

    span {
      display: inline-block;
      font-size: 34px;
      font-weight: 600;
      text-align: center;
      padding: 97px 0px 14px 0px;
      color: #333333;
      border-bottom: 2px solid #00a0ac;
      box-sizing: border-box;
    }
  }
  .producer {
    width: 1440px;
    margin: 0 auto;
    padding-bottom: 59px;
    img {
      width: 100%;
      height: 722.78px;
    }
  }
}
span.title {
  display: block;
  width: 140px;
  font-size: 34px;
  font-weight: 600;
  text-align: center;
  padding: 30px 0px 14px 0px;
  color: #333333;
  border-bottom: 2px solid #00a0ac;
  box-sizing: border-box;
}

// 新闻动态
.projectImg {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: auto;
  // margin: 24px 0;
  margin-top: 89px;
  margin-bottom: 129px;
  box-sizing: border-box;

  img {
    width: 100%;
  }
  .news_box {
    margin-top: 64px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    width: 100%;
    .text {
      padding: 42px 32px 40px 28px;
      box-sizing: border-box;
      width: 461px;
      height: 511px;
      box-shadow: 1.15px 1.64px 7.36px 0.16px rgba(120, 120, 120, 0.6);
      .new_title {
        padding-bottom: 17px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        border-bottom: 1px solid #dbdada;
        box-sizing: border-box;
        cursor: pointer;
        span:nth-child(1) {
          font-weight: 600;
          font-size: 26px;
        }
      }
      .text_item {
        width: 100%;
        .item {
          padding: 10px 10px;
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          div:nth-child(1) {
            width: 56px;
            height: 56px;
            background: #f6f6f6;
            border-radius: 50%;
            display: flex;
            align-items: center;
            justify-content: center;
            img {
              width: 34px;
              height: 35px;
            }
          }
          div:nth-child(2) {
            margin: 0px 9px 0px 20px;
            padding: 2px 15px;
            background: #00a0ac;
            border-radius: 5px;
            font-size: 18px;
            font-weight: 700;
            color: #ffffff;
            box-sizing: border-box;
          }
          div:nth-child(3) {
            font-size: 18px;
            font-weight: 400;
            text-align: justifyLeft;
            color: #333333;
            flex: 1;
          }
        }
      }
    }
    .video {
      padding: 42px 32px 40px 28px;
      box-sizing: border-box;
      width: 945.78px;
      height: 511px;
      box-shadow: 1.15px 1.64px 7.36px 0.16px rgba(120, 120, 120, 0.6);
      &:last-child {
        margin-right: 0;
      }
      .new_title {
        padding-bottom: 17px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        border-bottom: 1px solid #dbdada;
        box-sizing: border-box;
        cursor: pointer;
        span:nth-child(1) {
          font-weight: 600;
          font-size: 26px;
        }
        span:nth-child(2) {
          font-weight: 400;
          font-size: 20px;
          cursor: pointer;
        }
      }
      .video_item {
        display: flex;
        flex-wrap: wrap;
        width: 100%;
        .item {
          width: 50%;
          margin-top: 19px;
          display: flex;
          justify-content: flex-start;
          .img {
            margin-right: 31px;
            width: 160px;
            height: 104px;
            img {
              width: 160px;
              height: 104px;
            }
          }

          .info {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            height: 104px;
            color: #333;
            .name {
              font-size: 18px;
            }

            .time {
              font-size: 14px;
            }
          }
        }
      }
    }
  }
}
.videoView{
  .big_video {
    width: 1344px;
    height: 755px;
    margin: 0 auto;
    margin-bottom: 108px;
    video {
      // width: 100%;
      height: 100%;
    }
  }
  .title {
      margin: 0 auto;
      width: 1440px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 100px 0px 62px;
      box-sizing: border-box;
      .line {
        height: 1px;
        background-color: #dbdada;
        flex: 1;
      }
      .text {
        margin: 0px 12px;
        font-size: 34px;
        font-weight: 700;
        color: #333333;
        line-height: 24px;
      }
    }
}

</style>

<style scoped lang='scss'>
@import "./home-media"; // 手机端适配样式
</style>
